<template>
  <v-container>
    <!-- report feedback -->
    <loading-data v-if="preparing" :feedbackText="feedbackText"></loading-data>
    <v-row>
      <v-col cols="8">
        <h1>{{ $t("nav.calendar") }}</h1>
      </v-col>
      <v-col cols="4">
        <view-as-user-selection @onUserSelection="onUserSelection" />
      </v-col>
    </v-row>
    <template v-if="!preparing">
      <v-row>
        <v-col cols="12" md="6">
          <v-sheet tile height="54" class="d-flex">
            <v-btn icon @click="$refs.calendar.prev()">
              <v-icon>chevron_left</v-icon>
            </v-btn>
            <v-toolbar-title class="mt-1">
              <span v-if="$refs.calendar">{{
                $refs.calendar.title.toUpperCase()
              }}</span>
              <span v-else>{{ getCurrentMonthText() }}</span>
            </v-toolbar-title>
            <v-btn icon @click="$refs.calendar.next()">
              <v-icon>chevron_right</v-icon>
            </v-btn>
          </v-sheet>
          <v-row class="pl-5">
            <v-checkbox
              v-model="selected"
              label="BFH"
              :value="servicesNames.bigFamilyHouses"
              @change="loadEvents"
              :disabled="isCheckboxDisabled(servicesNames.bigFamilyHouses)"
            ></v-checkbox>
            <v-checkbox
              v-model="selected"
              label="MOK"
              :value="servicesNames.neighborhoodHouses"
              @change="loadEvents"
              :disabled="isCheckboxDisabled(servicesNames.neighborhoodHouses)"
            ></v-checkbox>
            <v-checkbox
              v-model="selected"
              label="MOK LIT"
              :value="servicesNames.mokLit"
              @change="loadEvents"
              :disabled="isCheckboxDisabled(servicesNames.mokLit)"
            ></v-checkbox>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          md="6"
          align="right"
          v-if="$root.currentUserData.userId === selectedUserId"
        >
          <employee-add-off-days-dialog @onSaveOffDays="onSaveOffDays" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-spacer />
            <b class="mb-4 mr-2">Vizītes kopā: {{ visitsCountText }}</b>
          </v-row>

          <v-sheet>
            <v-calendar
              ref="calendar"
              v-model="value"
              :weekdays="weekdays"
              type="month"
              :start="today"
              :locale="$i18n.locale"
              :now="today"
              :events="events"
              :event-color="getEventColor"
              :event-text-color="getEventTextColor"
              :event-more="false"
              @click:event="showEvent"
            >
              >
              <template v-slot:day-label="{ day, past, present, future, date }">
                <div class="text-center">
                  <v-btn
                    class="dayLabel black--text"
                    color="grey lighten-1"
                    v-if="past"
                    >{{ day }}</v-btn
                  >
                  <v-btn class="dayLabel todayCircle" v-else-if="present">{{
                    day
                  }}</v-btn>
                  <v-btn
                    class="dayLabel"
                    :color="getDayColor(date)"
                    v-else-if="future"
                    >{{ day }}</v-btn
                  >
                  <br />
                  <v-col cols="12">
                    <v-btn
                      v-if="future && isOffDay(date)"
                      color="red"
                      class="white--text mb-3"
                      style="width: 100%"
                      @click="(e) => onHolidayClick(e, date)"
                      ><span>Brīvdiena</span>
                    </v-btn>
                  </v-col>
                </div>
              </template>
            </v-calendar>
            <v-menu
              v-if="selectedEvent"
              v-model="selectedOpen"
              :close-on-content-click="false"
              :activator="selectedElement"
              offset-x
            >
              <v-card color="grey lighten-4" max-width="350px" flat>
                <v-toolbar
                  :color="selectedEvent.color"
                  :light="lightTheme(selectedEvent.color)"
                  :dark="!lightTheme(selectedEvent.color)"
                >
                  <v-btn icon>
                    <v-icon>
                      {{ selectedEvent.icon }}
                    </v-icon>
                  </v-btn>
                  <v-toolbar-title
                    v-html="selectedEvent.name"
                  ></v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text>
                  <span v-if="selectedEvent.type === 'visitsEvent'">
                    <ol>
                      <li v-for="(v, i) in selectedEvent.details" :key="i">
                        {{ v }}
                      </li>
                    </ol>
                  </span>
                  <span v-else v-html="selectedEvent.details"></span>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <custom-button
                    v-if="selectedEvent.type == 'offDayChoosen'"
                    text="Dzēst brīvdienu"
                    type="warningtext"
                    icon="delete"
                    @click="deleteOffDay(selectedEvent.start)"
                  />
                  <v-btn text color="accent" @click="selectedOpen = false">
                    {{ $t("btn.close") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-sheet>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { db, auth } from "@/main";

import LoadingData from "@/components/utilities/LoadingData.vue";

import { formatDateInRiga } from "@/helpers/dayjsDates.js";
import { debounce } from "debounce";
import {
  isEmpty,
  isNil,
  forEach,
  split,
  findIndex,
  indexOf,
  includes,
  some,
  isEqual,
  filter,
  concat,
} from "lodash";
import { firebaseCollectionsServices } from "@/helpers/constants";
import {
  dateToShortIsoString,
  dateToIsoString,
  monthTextinRiga,
  getIsoDatesBetweenTwoDates,
} from "@/helpers/dateTimeConvertingFunctions";
import ViewAsUserSelection from "@/components/ViewAsUserSelection.vue";
import CustomButton from "@/components/CustomButton.vue";
import EmployeeAddOffDaysDialog from "@/components/EmployeeAddOffDaysDialog.vue";
export default {
  components: {
    LoadingData,
    ViewAsUserSelection,
    CustomButton,
    EmployeeAddOffDaysDialog,
  },
  data() {
    return {
      preparing: false,
      today: dateToIsoString(new Date()),
      weekdays: [1, 2, 3, 4, 5, 6, 0],
      value: dateToIsoString(new Date()),
      selected: [],
      events: [],
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      allUsers: [],
      allClients: [],
      allShiftPlannings: [],
      servicesNames: firebaseCollectionsServices,
      selectedUserId: this.$root.currentUserData.userId,
      selectedUser: null,
      monthKeys: [],
      offDatesByClients: {},
      excludedClients: ["eDxAvzPFgL6LgjEaE3n0"],
    };
  },
  computed: {
    feedbackText() {
      return this.$t("global.loading-data");
    },
    visitsCountText() {
      let visitsCount = 0;
      let filteredVisits = this.events.filter((e) => e.type == "visitsEvent");
      let monthKey = this.value.substring(0, 7);
      filteredVisits.forEach((visit) => {
        if (visit.start.includes(monthKey)) {
          visitsCount += visit.visitsCount;
        }
      });
      return visitsCount;
    },
  },
  async created() {
    this.getMonthKeys();
    await this.getUserData();
    await this.getAllUsers();
    this.selected = this.$root.currentUserData.aiderServices || [
      firebaseCollectionsServices.neighborhoodHouses,
    ];
    this.preparing = true;
    await this.getClientsData();
    await this.getAllShiftPlannings();
    await this.getAdditionalClients();
    await this.getOffDays();
    await this.getHouseCareNewPlans();
    await this.loadEvents();
    this.preparing = false;
  },
  watch: {
    selectedUserId: function (val) {
      this.getUserData();
    },
  },
  methods: {
    async getAllUsers() {
      this.preparing = true;
      const usersList = [];
      let users = await db.collection("users").get();
      users.docs.forEach((user) => {
        usersList.push(user.data());
      });
      this.allUsers = usersList;
      this.preparing = false;
    },
    async getUserData() {
      this.preparing = true;
      let userResponse = await db
        .collection("users")
        .doc(this.selectedUserId)
        .get();
      if (userResponse.exists) {
        let user = userResponse.data();
        user.userId = this.selectedUserId;
        this.selectedUser = user;
      }
      this.preparing = false;
    },
    getCurrentMonthText() {
      const monthText =
        monthTextinRiga(new Date()) || new Date().toISOString().substring(0, 7);
      return monthText.toUpperCase();
    },
    onSaveOffDays() {
      this.preparing = true;
      setTimeout(() => {
        this.preparing = false;
      }, 200);
    },
    async deleteOffDay(date) {
      var conf = confirm("Vai Jūs tiešām vēlaties dzēst šo brīvdienu?");
      if (conf) {
        let offDaysList = this.$root.currentUserData.offDays || [];
        const foundIndex = indexOf(offDaysList, date);
        if (foundIndex > -1) {
          offDaysList.splice(foundIndex, 1);
          this.$root.currentUserData.offDays = offDaysList;
          this.selectedOpen = false;
          this.selectedEvent = null;
          await db
            .collection("users")
            .doc(this.$root.currentUserData.userId)
            .update({ offDays: offDaysList });
        }
        this.onSaveOffDays();
      } else {
        this.selectedOpen = false;
        this.selectedEvent = null;
      }
    },
    getDayColor(date) {
      return this.isOffDay(date) ? "red" : "teal darken-1";
    },
    isOffDay(date) {
      if (this.selectedUser) {
        let offDaysByGraphic = this.selectedUser.offDaysByGraphic || [];
        let offDays = this.selectedUser.offDays || [];
        let allOffDays = concat(offDaysByGraphic, offDays);
        return allOffDays.includes(date);
      } else {
        return false;
      }
    },
    onHolidayClick(nativeEvent, date) {
      const open = () => {
        this.selectedEvent = {
          color: "#F44336",
          name: "Brīvdiena",
          start: date,
          icon: "flight_takeoff",
        };
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.selectedOpen = true;
        }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    isCheckboxDisabled(service) {
      const adminRoles = ["admin", "manager", "sudo"];
      const intersection = filter(this.$root.currentUserData.role, (role) =>
        includes(adminRoles, role)
      );
      const haveService = includes(
        this.$root.currentUserData.aiderServices,
        service
      );
      return isEmpty(intersection) && !haveService;
    },
    async getClientsData() {
      let clients = await db
        .collection("clients")
        .where("clientStatus", "!=", "stopped")
        .orderBy("clientStatus")
        .get();
      let clientsList = [];
      clients.docs.forEach((client) => {
        if (!this.excludedClients.includes(client.id)) {
          let clientObj = client.data();
          clientObj.clientId = client.id;
          clientsList.push(clientObj);
        }
      });
      this.allClients = clientsList;
    },
    async getAdditionalClients() {
      let allClientsIds = [...this.allClients].map((client) => client.clientId);
      let additionalClientsIds = [];
      this.allShiftPlannings.forEach((planning) => {
        Object.keys(planning).forEach((shiftKey) => {
          if (!isEqual(shiftKey, "id")) {
            let [clientId] = split(shiftKey, "-", 1);
            if (
              !includes(allClientsIds, clientId) &&
              !includes(additionalClientsIds, clientId) &&
              !includes(this.excludedClients, clientId)
            ) {
              additionalClientsIds.push(clientId);
            }
          }
        });
      });
      let additionalClientsPromises = [];
      additionalClientsIds.forEach((clientId) => {
        additionalClientsPromises.push(
          db.collection("clients").doc(clientId).get()
        );
      });

      let clients = [...this.allClients];
      let additionalClientsResponse = await Promise.all(
        additionalClientsPromises
      );
      additionalClientsResponse.forEach((doc) => {
        let data = doc.data();
        if (data) {
          data.clientId = doc.id;
          clients.push(data);
        }
      });
      this.allClients = [...clients];
    },
    onUserSelection(user) {
      if (!isNil(user)) {
        this.selectedUserId = user.id;
        this.loadEvents();
      }
    },
    getMonthKeys() {
      const today = new Date();
      const thisYear = today.getFullYear();
      const lastYear = thisYear - 1;
      const nextYear = thisYear + 1;
      const years = [lastYear, thisYear, nextYear];

      const thisMonthKey = dateToShortIsoString(today);

      const prevMonthKey = dateToShortIsoString(
        new Date(thisYear, today.getMonth(), 0, 12)
      );

      const nextMonthKey = dateToShortIsoString(
        new Date(thisYear, today.getMonth() + 1, 1, 12)
      );

      this.monthKeys = [prevMonthKey, thisMonthKey, nextMonthKey];
    },
    async getAllShiftPlannings() {
      const monthKeys = this.monthKeys;
      const shiftsList = [];
      const shiftsPromisesList = [];
      forEach(monthKeys, (monthKey) => {
        forEach(this.servicesNames, (selectedService) => {
          const shiftsDocKey = `${selectedService}-${monthKey}`;
          let foundShifts = db
            .collection("shiftPlanning")
            .doc(shiftsDocKey)
            .get();
          shiftsPromisesList.push(foundShifts);
        });
      });
      let shiftsPromiseResult = await Promise.all(shiftsPromisesList);
      forEach(shiftsPromiseResult, (doc) => {
        if (doc.exists) {
          const data = doc.data();
          data.id = doc.id;
          shiftsList.push(data);
        }
      });
      this.allShiftPlannings = shiftsList;
    },
    async loadEvents() {
      this.preparing = true;
      this.getEvents();
      setTimeout(() => (this.preparing = false), 1000);
    },
    getEvents: debounce(async function () {
      let vm = this;
      let currentUserId = vm.selectedUserId;
      vm.events = [];
      if (isEmpty(vm.selected)) {
        return;
      }

      const today = new Date();
      const thisYear = today.getFullYear();
      const lastYear = thisYear - 1;
      const nextYear = thisYear + 1;
      const years = [lastYear, thisYear, nextYear];

      let dataObject = {};
      //CLIENTS
      const clientsList = [];
      forEach(vm.allClients, (client) => {
        if (includes(vm.selected, client.clientServices)) {
          clientsList.push(client);
        }
      });
      dataObject.clients = clientsList;

      //USERS
      const usersList = [];
      forEach(vm.allUsers, (user) => {
        if (
          !isEmpty(vm.selected) &&
          some(user.aiderServices, (serv) => includes(vm.selected, serv))
        ) {
          usersList.push(user);
        }
      });
      dataObject.users = usersList;

      const shiftsList = [];
      forEach(vm.allShiftPlannings, (shift) => {
        const shiftServiceSplit = split(shift.id, "-", 1);
        const shiftService = shiftServiceSplit[0];
        if (includes(vm.selected, shiftService)) {
          shiftsList.push(shift);
        }
      });
      dataObject.shifts = shiftsList;
      //creating client's birthdays
      forEach(dataObject.clients, (client) => {
        if (client.birthDate) {
          const [bdYear, month, day] = split(client.birthDate, "-");
          forEach(years, (year) => {
            const bd = `${year}-${month}-${day}`;
            const age = year - bdYear;
            const eventName = age + " " + client.clientName;
            const details = `${eventName} (${
              client.clientDistrictName
            }) ${this.$t("client.birthday").toLowerCase()} ${formatDateInRiga(
              client.birthDate
            )}`;
            let event = {
              name: eventName,
              color: "#" + client.clientColor,
              start: bd,
              details: details,
              icon: "cake",
            };
            const index = findIndex(vm.events, function (foundEvent) {
              return isEqual(foundEvent.name, eventName);
            });
            isEqual(index, -1) && vm.events.push(event);
          });
        }
      });
      //creating users's(workers) birthdays
      forEach(dataObject.users, (user) => {
        if (user.crmBirthDay) {
          const [bdYear, month, day] = split(user.crmBirthDay, "-");
          forEach(years, (year) => {
            const bd = `${year}-${month}-${day}`;
            const eventName = user.displayName;
            const details = `${eventName} (${this.$t(
              "users.employee"
            ).toLowerCase()}) ${this.$t("client.birthday").toLowerCase()}!`;
            let event = {
              name: eventName,
              color: "accent",
              start: bd,
              details: details,
              icon: "cake",
            };
            vm.events.push(event);
          });
        }
      });

      let monthsShiftsList = dataObject.shifts;
      let shiftEvents = [];
      forEach(monthsShiftsList, async (monthShift) => {
        Object.keys(monthShift).forEach((shiftKey) => {
          let responsible = monthShift[shiftKey];
          if (isEqual(responsible, currentUserId)) {
            const [clientId, year, month, date, visitNr] = split(shiftKey, "-");
            let isoDate = `${year}-${month}-${date}`;
            let foundClient = this.getClientById(clientId);

            if (!isNil(clientId) && foundClient) {
              let isOffDay = this.isOffDayVisit(clientId, isoDate, visitNr);
              let isPlanned = this.isVisitPlanned(clientId, isoDate, visitNr);
              if (isPlanned && !isOffDay) {
                if (!shiftEvents[isoDate]) {
                  shiftEvents[isoDate] = [];
                }
                shiftEvents[isoDate].push(shiftKey);
              }
            }
          }
        });
      });
      forEach(Object.keys(shiftEvents), (dateKey) => {
        let shiftDay = shiftEvents[dateKey].sort();
        let shiftsCount = shiftDay.length;
        let dayEvent = {};
        dayEvent.name = `${shiftsCount} ${this.$t("nav.visits").toLowerCase()}`;
        dayEvent.color = "primary";
        dayEvent.start = dateKey;
        dayEvent.icon = "location_on";
        dayEvent.type = "visitsEvent";
        dayEvent.visitsCount = shiftsCount;
        let details = [];

        shiftDay.forEach((shiftKey) => {
          const [clientId, yyyy, mm, dd, v] = split(shiftKey, "-");
          const foundClient = this.getClientById(clientId) || {};
          details.push(
            `${foundClient.clientName} ${this.$t(
              "visits.visit-nr"
            ).toLowerCase()}. ${parseInt(v) + 1};`
          );
        });
        dayEvent.details = details;
        !isEmpty(shiftDay) && vm.events.push(dayEvent);
      });
    }, 1000),

    getClientById(clientId) {
      return this.allClients.find((client) =>
        isEqual(client.clientId, clientId)
      );
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.selectedOpen = true;
        }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    getEventColor(event) {
      return event.color;
    },
    getEventTextColor(event) {
      const hex = event.color;
      const rgb = this.hexToRgb(hex);
      const lightness = this.getRelativeLuminance(rgb);
      if (lightness > 100) {
        return "black"; // Lighter color, return black
      } else {
        return "white"; // Darker color, return white
      }
    },
    lightTheme(hex) {
      if (!hex) {
        return;
      }
      const rgb = this.hexToRgb(hex);
      const lightness = this.getRelativeLuminance(rgb);
      return lightness > 100;
    },
    hexToRgb(hex) {
      //https://stackoverflow.com/a/39077686/2617776
      return hex
        .replace(
          /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
          (m, r, g, b) => "#" + r + r + g + g + b + b
        )
        .substring(1)
        .match(/.{2}/g)
        .map((x) => parseInt(x, 16));
    },
    getRelativeLuminance(rgb) {
      //https://stackoverflow.com/a/52879332/2617776
      return 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2];
    },
    async getOffDays() {
      let offDatesByClients = {};
      //START GET finished off days
      let finishedOffDaysPromises = [];
      this.allClients.forEach((client) => {
        finishedOffDaysPromises.push(
          db
            .collection("clients")
            .doc(client.clientId)
            .collection("offDays")
            .where("monthKeys", "array-contains-any", this.monthKeys)
            .get()
        );
      });
      let finishedOffDaysResponse = await Promise.all(finishedOffDaysPromises);
      finishedOffDaysResponse.forEach((response) => {
        response.docs.forEach((doc) => {
          let data = doc.data();
          let clientId = data.clientId;
          if (isNil(offDatesByClients[clientId])) {
            offDatesByClients[clientId] = [];
          }
          const dateFrom = new Date(data.outDate);
          const dateTo = data.returnDate
            ? new Date(data.returnDate)
            : new Date();
          let offDaysIsoDatesList = getIsoDatesBetweenTwoDates(
            dateFrom,
            dateTo
          );
          let startInMinutes =
            parseInt(data.outHours) * 60 + parseInt(data.outMinutes);
          let endInMinutes =
            parseInt(data.returnHours) * 60 + parseInt(data.returnMinutes);
          let lastIndex = offDaysIsoDatesList.length - 1;
          offDaysIsoDatesList.forEach((isoDate, index) => {
            let obj = {
              date: isoDate,
              startInMinutes: isEqual(index, 0) ? startInMinutes : null,
              endInMinutes: isEqual(index, lastIndex) ? endInMinutes : null,
            };
            offDatesByClients[clientId].push(obj);
          });
        });
      });
      this.offDatesByClients = Object.assign({}, offDatesByClients);
    },
    async getHouseCareNewPlans() {
      let clientsList = [...this.allClients];
      let houseCareNewPlansPromises = [];
      clientsList.forEach((client) => {
        let clientId = client.clientId;
        houseCareNewPlansPromises.push(
          db
            .collection("clients")
            .doc(clientId)
            .collection("houseCareNewPlans")
            .get()
        );
      });
      let houseCareNewPlansResponses = await Promise.all(
        houseCareNewPlansPromises
      );
      let houseNewCarePlansByClient = {};
      houseCareNewPlansResponses.forEach((response) => {
        response.docs.forEach((doc) => {
          let data = doc.data();
          if (isNil(houseNewCarePlansByClient[data.clientId])) {
            houseNewCarePlansByClient[data.clientId] = [];
          }
          let startDate = new Date(data.dateFrom);
          startDate.setHours(1, 0, 0, 0);
          let endDate = new Date(data.dateTo ? data.dateTo : "2099-12-31");
          endDate.setHours(23, 59, 0, 0);
          data.startTime = startDate.getTime();
          data.endTime = endDate.getTime();
          houseNewCarePlansByClient[data.clientId].push(data);
        });
      });
      Object.keys(houseNewCarePlansByClient).forEach((clientId) => {
        let foundIndex = clientsList.findIndex((client) =>
          isEqual(client.clientId, clientId)
        );
        if (foundIndex > -1) {
          clientsList[foundIndex].houseCareNewPlans =
            houseNewCarePlansByClient[clientId];
        }
      });
      this.allClients = [...clientsList];
    },
    isOffDayVisit(clientId, isoDate, visitNr) {
      let client = this.getClientById(clientId);
      if (client) {
        let visitDate = new Date(isoDate);
        visitDate.setHours(12, 0, 0, 0);
        let clientServiceEndDate = client.nbhServiceEndDate
          ? new Date(client.nbhServiceEndDate)
          : new Date("2099-12-31");
        clientServiceEndDate.setHours(15, 0, 0, 0);
        if (clientServiceEndDate.getTime() > visitDate.getTime()) {
          let clientOffDays = this.offDatesByClients[clientId];
          let isOffDay = false;
          if (clientOffDays) {
            let foundOffDay = clientOffDays.find((day) =>
              isEqual(day.date, isoDate)
            );
            if (foundOffDay) {
              let foundScheduledVisitTime = this.getScheduledVisitTime(
                clientId,
                visitNr
              );
              if (foundScheduledVisitTime) {
                let scheduledVisitStartInMinutes =
                  foundScheduledVisitTime.startInMinutes;
                let isFullOffDay =
                  isNil(foundOffDay.startInMinutes) &&
                  isNil(foundOffDay.endInMinutes);
                let isAfterOffDayStart =
                  !isNil(foundOffDay.startInMinutes) &&
                  scheduledVisitStartInMinutes > foundOffDay.startInMinutes;
                let isBeforeOffDayEnd =
                  !isNil(foundOffDay.endInMinutes) &&
                  scheduledVisitStartInMinutes < foundOffDay.endInMinutes;
                isOffDay =
                  isFullOffDay || isAfterOffDayStart || isBeforeOffDayEnd;
              }
            }
          }
          return isOffDay;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    getScheduledVisitTime(clientId, visitNr) {
      let client = this.allClients.find((client) =>
        isEqual(client.clientId, clientId)
      );
      let foundScheduledVisitTime = client.visitsScheduleTimes.find(
        (time) =>
          isEqual(time.index, parseInt(visitNr)) || isEqual(time.index, visitNr)
      );
      if (foundScheduledVisitTime) {
        foundScheduledVisitTime.startInMinutes =
          parseInt(foundScheduledVisitTime.hours) * 60 +
          parseInt(foundScheduledVisitTime.minutes);
        return foundScheduledVisitTime;
      } else {
        return null;
      }
    },
    isVisitPlanned(clientId, isoDate, visitNr) {
      let client = this.getClientById(clientId);
      let isPlanned = true;
      let date = new Date(isoDate);
      let weekDay = date.getDay();
      date.setHours(12, 0, 0, 0);
      if (client) {
        if (client.houseCareNewPlans && !isEmpty(client.houseCareNewPlans)) {
          let time = date.getTime();
          let foundPlan = client.houseCareNewPlans.find(
            (plan) => plan.startTime <= time && plan.endTime >= time
          );
          if (foundPlan && foundPlan.visitsByDay) {
            let visitsCount = foundPlan.visitsByDay[weekDay];
            isPlanned = visitsCount >= parseInt(visitNr) + 1;
          }
        } else {
          if (client.visitsScheduleTimes) {
            let foundTime = client.visitsScheduleTimes.find(
              (time) => time.index == parseInt(visitNr)
            );
            isPlanned = !isNil(foundTime);
          }
        }
      } else {
        isPlanned = false;
      }

      return isPlanned;
    },
  },
};
</script>

<style scoped>
.dayLabel {
  height: 40px !important;
  width: 40px !important;
  min-width: 40px !important;
  border-radius: 20px;
  margin-bottom: 10px;
  color: white !important;
}
.todayCircle {
  -webkit-animation: blink-animation 1s infinite; /* Safari 4+ */
  -moz-animation: blink-animation 1s infinite; /* Fx 5+ */
  -o-animation: blink-animation 1s infinite; /* Opera 12+ */
  animation: blink-animation 1s infinite;
}
@-webkit-keyframes blink-animation {
  0%,
  49% {
    background-color: #7e57c2;
    border: 3px solid #03a9f4;
  }
  50%,
  100% {
    background-color: #03a9f4;
    border: 3px solid #7e57c2;
  }
}
</style>