<template>
  <div>
    <custom-button
      text="Pievienot brīvdienas"
      icon="flight_takeoff"
      @click="dialogOpen = true"
    />
    <v-dialog v-model="dialogOpen" persistent max-width="800">
      <v-card class="px-2 center">
        <loading-data
          v-if="loadingData"
          :feedbackText="$t('global.loading-data')"
        ></loading-data>
        <template v-else>
          <v-row align="center" justify="center">
            <h1 class="py-5">Pievienot brīvdienas</h1>
          </v-row>
          <v-row class="mx-6">
            <v-col cols="12" sm="4">
              <v-checkbox v-model="onlyOneDay" label="Tikai 1 diena" />
            </v-col>
            <v-col cols="12" sm="4">
              <select-date
                labelText="Sākuma datums"
                :minDate="tommorrowDate"
                :loadedDate="startDate"
                :future="true"
                @newDate="onStartDateSelected"
              />
            </v-col>
            <v-col cols="12" sm="4" v-if="!onlyOneDay">
              <select-date
                labelText="Beigu datums"
                :minDate="minEndDate || tommorrowDate"
                :loadedDate="endDate"
                :future="true"
                @newDate="onEndDateSelected"
              />
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <custom-button
              type="warningtext"
              icon="close"
              :text="$t('btn.cancel')"
              @click="dialogOpen = false"
            />
            <custom-button
              type="textBtn"
              icon="save"
              :text="$t('btn.save')"
              :disabled="isAddDisabled()"
              @click="saveOffDays()"
            />
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { db } from "@/main";
import { find, forEach, includes, isEqual, isNil, filter } from "lodash";
import CustomButton from "./CustomButton.vue";
import SelectDate from "./utilities/SelectDate.vue";
import { getIsoDatesBetweenTwoDates } from "@/helpers/dateTimeConvertingFunctions";
import LoadingData from "./utilities/LoadingData.vue";
export default {
  components: { CustomButton, SelectDate, LoadingData },
  data() {
    return {
      dialogOpen: false,
      startDate: null,
      endDate: null,
      tommorrowDate: null,
      onlyOneDay: false,
      minEndDate: null,
      loadingData: false,
    };
  },
  watch: {
    dialogOpen: function (val) {
      if (!val) {
        this.startDate = null;
        this.endDate = null;
        this.minEndDate = null;
        this.onlyOneDay = false;
      } else {
        this.startDate = null;
        this.endDate = null;
        this.minEndDate = null;
        this.onlyOneDay = false;
        let today = new Date();
        this.tommorrowDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() + 2
        )
          .toISOString()
          .substring(0, 10);
      }
    },
  },
  methods: {
    async saveOffDays() {
      this.loadingData = true;
      let offDaysList = this.$root.currentUserData.offDays || [];
      if (this.onlyOneDay) {
        const isOffDayByGraphic = this.isHolidayByGraphic(this.startDate);
        if (
          !find(offDaysList, (date) => isEqual(date, this.startDate)) &&
          !isOffDayByGraphic
        ) {
          offDaysList.push(this.startDate);
        }
      } else {
        const startingDate = new Date(this.startDate);
        const endingDate = new Date(this.endDate);
        const isoDatesList = getIsoDatesBetweenTwoDates(
          startingDate,
          endingDate
        );
        const filteredIsoDates = filter(
          isoDatesList,
          (isoDate) => !this.isHolidayByGraphic(isoDate)
        );
        forEach(filteredIsoDates, (isoDate) => {
          if (!find(offDaysList, (date) => isEqual(date, isoDate))) {
            offDaysList.push(isoDate);
          }
        });
      }

      this.$root.currentUserData.offDays = offDaysList;
      await db
        .collection("users")
        .doc(this.$root.currentUserData.userId)
        .update({ offDays: offDaysList });
      this.loadingData = false;
      this.dialogOpen = false;
      this.$emit("onSaveOffDays");
    },
    isHolidayByGraphic(date) {
      const dateTime = new Date(date);
      const dayOfWeek = dateTime.getDay();
      const graphicOffDays = filter(
        this.$root.currentUserData.workDays,
        (day) => day.isOffDay
      ).map((day) => day.day);
      const isOffDayByGraphic = includes(graphicOffDays, dayOfWeek);
      return isOffDayByGraphic;
    },
    isAddDisabled() {
      const isEnabled =
        !isNil(this.startDate) && (this.onlyOneDay || !isNil(this.endDate));
      return !isEnabled;
    },
    onStartDateSelected(dateSelected) {
      this.startDate = dateSelected;
      const date = new Date(dateSelected);
      date.setHours(12);
      this.minEndDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() + 2
      )
        .toISOString()
        .substring(0, 10);
      const endingDate = new Date(this.endDate);
      const minDate = new Date(this.minEndDate);
      if (endingDate < minDate) {
        this.endDate = this.minEndDate;
      }
    },
    onEndDateSelected(dateSelected) {
      this.endDate = dateSelected;
      const date = new Date();
    },
  },
};
</script>

<style>
</style>